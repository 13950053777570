$(document).ready(function () {

    gsap.fromTo(".process-1", { opacity: 0, x: -200 }, {
        x: 0, opacity: 1, duration: 2,
        scrollTrigger: {
            trigger: ".process",
            toggleActions: 'restart pause restart pause'
        }
    });

    gsap.fromTo(".process-2", { opacity: 0, x: 200 }, {
        x: 0, opacity: 1, duration: 2, delay: 0.5,
        scrollTrigger: {
            trigger: ".process",
            toggleActions: 'restart pause restart pause'
        }
    });

    gsap.fromTo(".process-3", { opacity: 0, x: -200 }, {
        x: 0, opacity: 1, duration: 2, delay: 1,
        scrollTrigger: {
            trigger: ".process",
            toggleActions: 'restart pause restart pause'
        }
    });

    gsap.fromTo(".process-4", { opacity: 0, x: 200 }, {
        x: 0, opacity: 1, duration: 2, delay: 1.5,
        scrollTrigger: {
            trigger: ".process",
            toggleActions: 'restart pause restart pause'
        }
    });

    
    gsap.fromTo(".process-5", { opacity: 0, x: -200 }, {
        x: 0, opacity: 1, duration: 2, delay: 2,
        scrollTrigger: {
            trigger: ".process",
            toggleActions: 'restart pause restart pause'
        }
    });

    gsap.fromTo(".process-6", { opacity: 0, x: 200 }, {
        x: 0, opacity: 1, duration: 2, delay: 2.5,
        scrollTrigger: {
            trigger: ".process",
            toggleActions: 'restart pause restart pause'
        }
    });

});